<template>
    <transition>
        <div>
            <div class="modal fade custom-fields show" style="display: block" id="custom-field">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content" v-on-click-away="closeModal">
                        <form action="">
                            <div class="modal-header d-flex justify-content-between align-items-center">
                                <h4 class="modal-title text-capitalize">{{ $t("Update assessment") }}</h4>
                                <button type="button" style="min-width: 25px;" class="close" @click="closeModal">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <label for="selected_assessment"> {{ $t(`Assessment`) }}</label>
                                            <p id="selected_assessment" v-text="assessment.name"></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <label id="expire_date">{{ $t(`Expire date`) }}*</label>
                                            <date-picker
                                                    v-model="form.expire_date"
                                                    :placeholder="$t('mm/dd/yyyy')"
                                                    autocomplete="off"
                                                    :config="datePickerOptions"
                                            ></date-picker>
                                            <error-message :message="$t(errors.expire_date)"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="modal-footer d-flex justify-content-between">
                                <div class="w-100 d-flex justify-content-between align-items-top" style="gap: 10px">
                                    <span class="text" style="font-size: 0.75rem;">{{ $t('Note: Candidate will be notified by message.') }}</span>
                                    <submit-button :click="saveData" class="info-button ml-auto text-capitalize" :loading="isLoading" style="flex:0 0 auto">
                                        {{ $t("Update assessment") }}
                                    </submit-button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div class="modal-backdrop fade show"></div>
        </div>
    </transition>
</template>
<script>
import Multiselect from 'vue-multiselect';
import _ from 'lodash';
import ErrorMessage from "../../common/ErrorMessage";
import client from "../../../app/api/Client";
import moment from "moment";
import {datePickerIcons} from "../../../config/options";
import datePicker from 'vue-bootstrap-datetimepicker';
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';


export default {
    props: [
        'job', 'assessment'
    ],
    components: {
        ErrorMessage,
        datePicker,
        Multiselect
    },
    data() {
        return {
            form: {
                expire_date: '',
            },
            errors: {},
            selected_assessment: null,
            assessments: [],
            isLoading: false,
            assessmentEdit: null,
        }
    },
    computed: {
        datePickerOptions() {
            return {
                format: 'MM/DD/YYYY',
                minDate: moment().format('MM/DD/YYYY'),
                icons: datePickerIcons,
                useCurrent: false
            }
        }
    },
    methods: {
        closeModal() {
            this.$emit('closeModal');
        },
        async saveData() {
            if (!this.isValidated()) {
                return false;
            }
            this.isLoading = true;
            try {
                let {data} = await client().post(`/job/${this.job}/assessment/${this.assessment.id}/update`, this.form);
                if (data.status === 'SUCCESS') {
                    this.$toast.success(this.$t(data.message));
                } else {
                    this.$toast.error(this.$t(data.message));
                }
            } catch (err) {
                if (err.response.status === 422) {
                    this.errors = _helper.serializeValidationMessage(err.response.data.message);
                    this.$toast.error(this.$t('Oops! Please check your input'));
                } else {
                    this.$toast.error(this.$t(err.response.data.message));
                }
            }
            this.isLoading = false;
            this.$emit('assessmentUpdated');
        },
        isValidated() {
            this.errors = {};
            if (_.isNull(this.form.expire_date) || (!this.form.expire_date.length > 0 && !_.isDate(this.form.expire_date))) {
                this.errors.expire_date = `Expire date is required.`;
                return false;
            }
            return true;
        },
    },
    mounted() {
        if (this.assessment && (moment(this.assessment.expire_date).format() >= moment().startOf('day').format())) {
            this.form.expire_date = moment(this.assessment.expire_date).format('MM/DD/YYYY');
        } else {
            this.form.expire_date = null;
        }
    }
}
</script>

<style scoped>
.fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
{
    opacity: 0;
}

.modal-body {
    overflow-y: unset !important;
}
</style>
