<template>
    <div style="margin-top: -10px;">
        <div v-if="canOrganizeCandidate" class="details__card" style="border-radius: 0 0 10px 10px;">
            <div class="details__card__body">
                <div class="form-group d-flex">
                    <div style="flex: 1">
                        <input type="text" v-model="form.name"
                               :placeholder="$t('Attachment Name')"
                               class="label__full--primary form-control"
                               maxlength="100"
                               ref="attachment_name"
                        />
                        <div class="error-message" v-text="errors.name"></div>
                    </div>
                    <div class="ml-2" style="width: 200px">
                        <label class="file-browse-button button semi-button-info">
                            <span>{{ $t("Browse") }}..</span>
                            <input type="file" ref="attachment" name="attach" @change="attachFile()" class="myfile">
                        </label>
                        <div class="error-message" v-text="errors.attachment"></div>
                    </div>
                </div>
                <div class="form-group">
                    <textarea v-model="form.comment" rows="4"
                              :placeholder="$t('Add comment')"
                              maxlength="200"
                              class="label__full--primary form-control">
                    </textarea>
                    <small class="float-right text-muted">
                      <span>{{ $t('Max') }}</span> <span>{{ $t('200') }}</span>
                    </small>
                    <div class="error-message" v-text="errors.comment"></div>
                </div>
                <div class="form-group d-flex">
                    <submit-button :click="uploadAttachment" type="primary-button" :loading="isLoading">{{ $t("Upload") }}</submit-button>
                    <button type="button" @click.prevent="clearForm" class="button semi-button-danger ml-2">{{ $t('Cancel') }}</button>
                </div>
            </div>
        </div>
        <div class="data-table attachment-table">
            <h4 class="empty-message" v-if="isEmpty">{{ $t(`No attachments found.`) }}</h4>
            <div class="table-wrap" v-else>
                <div class="table">
                    <div class="table__row attachment-item" v-for="(attachment, key) in attachments" :key="key">
                        <div class="table-cell file-name">
                            <a href="#" target="_blank" @click.prevent="downloadAttachment(attachment)">{{ attachment.name }}</a>
                            <p class="text-gray text-break">{{ attachment.comment }}</p>
                        </div>
                        <div class="table-cell candidate__name">
                            <p>{{ attachment.creator.name }}</p>
                            <p class="text-gray text-break" v-html="dateConvertToSpan(attachment.created_at)"></p>
                        </div>
                        <div class="table-cell d-flex" v-if="canOrganizeCandidate">
                            <a href="javascript:void(0)" @click.prevent="editAttachment(attachment)" class="control-button control-button--info has_bg_color">
                                <div class="control-button__icon">
                                    <i class="eicon e-pencil"></i>
                                </div>
                            </a>
                            <a href="javascript:void(0)" @click.prevent="deleteAttachment(attachment)" class="control-button control-button--danger has_bg_color">
                                <div class="control-button__icon">
                                    <i class="eicon e-delete"></i>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <modal-update-attachment :attachment="formAttachment" :applicantId="applicantId" v-if="showUpdateAttachment" @closeAttachmentTemplateModal="closeUpdateAttachmentModal"></modal-update-attachment>
    </div>
</template>

<script>
import client from "../../../app/api/Client";
import {queryUrl} from "../../../extra/helper";
const ModalUpdateAttachment = () =>  import('../../../components/modal/job/UpdateAttachment');
import { dateConvertSpan } from '../../../extra/helper';
import {canOrganizeCandidate} from "../../../config/permission";

export default {
    name: "TabAttachment",
    components: {
        ModalUpdateAttachment
    },
    props: ['applicantId'],
    data() {
        return {
            isLoading: false,
            attachments: [],
            form: {
                name: '',
                comment: '',
                attachment: '',
            },
            errors: {
                name: '',
                comment: '',
                attachment: '',
            },
            showUpdateAttachment: false,
            formAttachment: null,
        }
    },
    computed: {
        isEmpty() {
            return !this.attachments.length;
        },
        canOrganizeCandidate() {
            return canOrganizeCandidate();
        },
    },
    methods: {
        dateConvertToSpan(date) {
            return dateConvertSpan(date);
        },
        editAttachment(attachment) {
            this.formAttachment = attachment;
            this.showUpdateAttachment = true;
        },
        attachFile() {
            this.errors.attachment = '';
            let FileSize = this.$refs.attachment.files[0].size / 1024 / 1024; // in MB
            if (FileSize > 2) {
                this.errors.attachment = this.$t(`File size should be under 2 mb!`);
                const input = this.$refs.attachment;
                input.type = 'text';
                input.type = 'file';
                this.$toast.error(this.$t(`File size should be under 2 mb!`));
            } else {
                this.form.attachment = this.$refs.attachment.files[0];
                if (!this.form.name) {
                    let name = this.form.attachment.name.split('.');
                    let newName = _.dropRight(name);
                    this.form.name = newName.join('.');
                }
            }
        },

        async uploadAttachment() {
            const formData = new FormData();
            formData.append('name', this.form.name);
            formData.append('comment', this.form.comment);
            formData.append('attachment', this.form.attachment);
            this.isLoading = true;
            try {
                let {data: {data, message}} = await client().post(`job/applicant/${this.applicantId}/attachment`, formData);
                this.$toast.success(message);
                this.clearForm();
                await this.getAttachments();
            } catch (err) {
                if (err.response.status === 422) {
                    this.errors = _helper.serializeValidationMessage(err.response.data.message);
                    this.$toast.error(this.$t('Oops! Please check your input'));
                } else {
                    this.$toast.error(err.response.data.message);
                }
            }
            this.isLoading = false;
        },

        clearForm() {
            this.form = {
                name: '',
                comment: '',
                attachment: '',
            };
            this.errors = {
                name: '',
                comment: '',
                attachment: '',
            };
        },

        async getAttachments() {
            try {
                let {data: {data}} = await client().get(`job/applicant/${this.applicantId}/attachments`);
                this.attachments = data;
            } catch (err) {
                this.$toast.error(err.response.data.message);
            }
        },
        deleteAttachment(attachment) {
            let message = {
                title: this.$t(`Confirmation`),
                body: this.$t(`Are you sure, you want to remove this attachment?`)
            };
            let dialogConfig = {
                okText: this.$t('yes'),
                cancelText: this.$t('no'),
            };

            this.$dialog.confirm(message, dialogConfig).then(() => {
                client().delete(`/job/applicant/${this.applicantId}/attachment/${attachment.id}/delete`)
                    .then(({data: {message}}) => {
                        this.$toast.success(this.$t(message));
                        this.getAttachments();
                    })
                    .catch(({response: {data}}) => {
                        this.$toast.error(data.message);
                    });
            });
        },
        downloadAttachment(attachment) {
            let params = {
                token: this.$store.state.token
            };
            let url = `/job/applicant/${this.applicantId}/attachment/${attachment.id}/download`;
            window.open(queryUrl(url, params), '_blank');
        },
        closeUpdateAttachmentModal() {
          this.showUpdateAttachment = false;
          this.getAttachments();
        }
    },

    async mounted() {
        await this.getAttachments();
        this.$nextTick(() => {
            if(typeof this.$refs.attachment_name !== 'undefined') {
                this.$refs.attachment_name.focus();
            }
        });
    }
}
</script>

<style scoped>

.page-body .content-area__body .data-table .table-wrap .table .table__row {
    display: flex;
}

.attachment-table .table-cell {
    font-size: .9rem;
    padding: 15px 20px !important;
    flex: 1 1 auto;
    word-break: break-all;
}

.page-body .content-area__body .data-table .table-wrap .table .table__row .table-cell:last-child {
    flex: 0 0 110px;
}
.page-body .content-area__body .data-table .table-wrap .table .table__row .table-cell.candidate__name {
    min-width: 160px;
    flex: 0 0 160px;
}
.page-body .content-area__body .data-table .table-wrap .table .table__row .table-cell:first-child {
    min-width: 200px;
}

.attachment-table .text-gray {
    color: #7d8091;
    font-size: .8rem;
}

.attachment-table .table-cell .control-button {
    max-width: 30px;
}

.attachment-table .table {
    border-spacing: 0 2px !important;
}

.page-body .content-area__body .data-table .table-wrap .table .table__row .table-cell:not(:last-child) {
    border-right: 2px solid #f5f7fd;
}
.page-body .content-area__body .data-table .table-wrap .table .table__row .table-cell.candidate__name {
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.page-body .content-area__body .data-table .table-wrap .table .table__row .table-cell:last-child {
    display: flex;
    align-items: center;
}
.attachment-item {
    margin-bottom: 5px;
    border-radius: 10px;
}

@media all and (max-width: 767px) {
    .page-body .content-area__body .data-table .table-wrap .table .table__row .table-cell:not(:last-child) {
        border-right: 0 solid #f5f7fd;
    }
}

</style>
