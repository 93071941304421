<template>
    <div class="error-message" v-if="message">{{ $t(message) }}</div>
</template>

<script>
    export default {
        name: "ErrorMessage",
        props: ['message']
    }
</script>

<style scoped>

</style>
