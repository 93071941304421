<template>
    <div class="candidate-details-tab__body">
        <div class="details__card gutter-10" id="loading" v-if="isContentLoading">
            <div class="details__card__body text-center">
                <div class="alert alert-success" role="alert">
                    <strong>{{ $t('please wait') }} ...</strong>
                </div>
            </div>
        </div>
        <template>
            <div class="details__card gutter-10" v-if="showAiScore">
                <div class="details__card__head">
                    <h4>{{ $t("AI Score") }}</h4>
                    <div v-if="loading">
                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="background: none; shape-rendering: auto;" width="24px" height="24px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                            <circle cx="50" cy="50" fill="none" stroke="#597dfc" stroke-width="10" r="35" stroke-dasharray="164.93361431346415 56.97787143782138">
                                <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" values="0 50 50;360 50 50" keyTimes="0;1"></animateTransform>
                            </circle>
                        </svg>
                        {{ $t('Calculating AI Score..') }}
                    </div>
                </div>
                <div class="details__card__body">

                    <div class="d-flex justify-content-between flex-wrap">
                        <div class="text-center" v-if="skillsSeries[0] != null">
                            <p>{{ $t('Skills') }}</p>
                            <apexchart type="radialBar" ref="skillsChart" height="120" width="120" :options="skillsChartOptions" :series="skillsSeries"></apexchart>
                        </div>
                        <div class="text-center" v-if="educationSeries[0] != null">
                            <p>{{ $t('Education') }}</p>
                            <apexchart type="radialBar" ref="eductionChart" height="120" width="120" :options="educationChartOptions" :series="educationSeries"></apexchart>
                        </div>
                        <div class="text-center" v-if="experienceSeries[0] != null">
                            <p>{{ $t('Experience') }}</p>
                            <apexchart type="radialBar" ref="experienceChart" height="120" width="120" :options="experienceChartOptions" :series="experienceSeries"></apexchart>
                        </div>
                        <div class="text-center" v-if="totalSeries[0] != null">
                            <p>{{ $t('Total') }}</p>
                            <apexchart type="radialBar" ref="totalChart" height="120" width="120" :options="totalChartOptions" :series="totalSeries"></apexchart>
                        </div>
                    </div>
                </div>
            </div>

            <div class="details__card question__score gutter-10">
                <div class="details__card__head">
                    <a href="#" class="details__card__head__tab"
                       v-if="hasQuiz"
                       @click.prevent="switchTab(1)"
                       :class="{'active' : isQuiz}"
                    >{{ $t("Quiz") }}</a>
                    <a href="#" class="details__card__head__tab"
                       v-if="hasScreeningQuestion"
                       @click.prevent="switchTab(2)"
                       :class="{'active' : isScreening}"
                    >{{ $t("screening questions") }}</a>
                    <a href="#" class="details__card__head__tab"
                       v-if="hasAssessment"
                       @click.prevent="switchTab(3)"
                       :class="{'active' : isAssessment}"
                    >{{ $t("Assessments") }}</a>
                    <a href="#"
                       @click.prevent="switchTab(4)"
                       class="details__card__head__tab"
                       :class="{'active' : isAttachment}"
                    >{{ $t("Attachment") }}</a>
                </div>
                <template v-if="showQuiz">
                    <div class="details__card__body text-center" v-if="quizQuestions.length==0">
                        <h4 class="empty-message">{{ $t(`No quizzes found.`) }}</h4>
                    </div>
                    <template v-else>
                        <div class="details__card__body d-flex justify-content-between align-items-center">
                            <ul class="info__list question-answer">
                                <li class="list-item--primary">
                                    <p class="question">{{ $t("Total Questions") }}: <span>{{ quiz.count }}</span></p>
                                </li>
                                <li class="list-item--primary">
                                    <p class="question">{{ $t("Total Marks") }}: <span>{{ quiz.totalMarks }}</span></p>
                                </li>
                                <li class="list-item--primary">
                                    <p class="question">{{ $t("Marks Obtained") }}: <span>{{ quiz.marksObtained }}</span></p>
                                </li>
                            </ul>
                            <div class="text-right total-mark-obtained">
                                <apexchart type="radialBar" ref="quizChart" height="120" width="120" :options="quizChartOptions" :series="[quizPercent]"></apexchart>
                            </div>
                        </div>
                        <div class="details__card__body">
                            <ul class="info__list question-answer">
                                <li class="list-item--primary" v-for="(question, index) in quizQuestions">
                                    <p class="question"><b>{{ $t("Question") }}: {{ (index + 1) }}.</b> <span class="font-weight-normal">{{ question.asked }}</span></p>
                                    <p class="label__full--modified label__full--primary answer">
                                        <span class="ans-label"> {{ $t("Applicant answered") }}: </span>
                                        <span id="applicant-quiz-answer">{{ question.answer }}</span>
                                        <span v-if="question.is_skipped" id="applicant-quiz-skipped" class="text-danger">{{ $t("Skipped") }}</span>
                                        <template v-if="question.type == job.QUESTION_TYPES.MULTIPLE_CHOICE">
                                            <label v-if="!question.is_correct" class="correct-ans">
                                                <span class="prefix">{{ $t("Correct answer") }}: </span>
                                                <span class="answer" id="correct-quiz-answer">{{ question.correct_answer }}</span>
                                            </label>
                                            <span v-if="question.is_correct" class="result-check correct"><i class="eicon e-checkmark"></i></span>
                                            <span v-else-if="(!question.is_skipped && !question.is_correct)" class="result-check wrong">
                                                <i class="eicon e-close"></i>
                                            </span>
                                        </template>
                                    </p>
                                </li>
                            </ul>
                        </div>
                    </template>
                </template>

                <template v-else-if="showScreeningQuestion">
                    <div class="details__card__body text-center" v-if="questions.length==0">
                        <h4 class="empty-message">{{ $t(`No questions found.`) }}</h4>
                    </div>
                    <template v-else>
                        <div class="details__card__body">
                            <ul class="info__list question-answer">
                                <li class="list-item--primary" v-for="(question, index) in questions">
                                    <p class="question"><b>{{ $t("Question") }}: {{ (index + 1) }}.</b> <span class="font-weight-normal">{{ question.asked }}</span></p>
                                    <p class="label__full--modified label__full--primary answer">
                                        <span class="ans-label"> {{ $t("Applicant answered") }}:</span>
                                        <span id="applicant-screen-answer" v-html="transformUrlToLink(question.answer)"></span>
                                        <span v-if="question.is_skipped" id="applicant-screen-skipped" class="text-danger">{{ $t("Skipped") }}</span>
                                        <template v-if="question.type == job.QUESTION_TYPES.MULTIPLE_CHOICE">
                                            <label v-if="!question.is_correct && question.correct_answer!=''" class="correct-ans">
                                                <span class="prefix">{{ $t("Correct answer") }}: </span>
                                                <span class="answer" id="correct-screen-answer" v-html="transformUrlToLink(question.answer)"></span>
                                            </label>
                                            <span v-if="question.is_correct" class="result-check correct"><i class="eicon e-checkmark"></i></span>
                                            <span v-else-if="(!question.is_correct && question.correct_answer!=='' && !question.is_skipped)" class="result-check wrong"><i class="eicon e-close"></i></span>
                                        </template>
                                    </p>
                                </li>
                            </ul>
                        </div>
                    </template>
                </template>
                <template v-else-if="showAssessment">
                    <div class="accordion main__assessment__area" id="easyjobsAccordon">
                        <div class="card" v-for="(assessment, index)  in assessments">
                            <div class="card-header collapse-header d-flex justify-content-between align-items-center" :id="`assessment-${assessment.id}`" @click.prevent="showAssessmentDetails(assessment.id)" :class="{'open-card-header': assessment.show}">
                                <div>
                                    <div class="d-flex align-items-center assessment-header">
                                        <i class="eicon  e-sort-down" style="font-size: 1rem;" v-if="!assessment.show"></i>
                                        <i class="eicon  e-sort-up" style="font-size: 1rem;" v-if="assessment.show"></i>
                                        <p class="text-break display-block">{{ assessment.name }}</p>
                                    </div>
                                    <div class="d-flex align-items-center assessment-header">
                                        <div class="semi-button h-modified w-20 primary-label m-2" :class="assessment.status" v-if="assessment.status == 'ended'">{{ $t('Completed') }}</div>
                                        <div class="semi-button h-modified w-20 primary-label m-2" :class="assessment.status" v-if="assessment.status == ''">{{ $t('Pending') }}</div>
                                        <div class="semi-button h-modified w-20 primary-label m-2" :class="assessment.status" v-if="assessment.status == 'not-completed'">{{ $t('Expired') }}</div>
                                        <p v-if="assessment.hasOwnProperty('marks_obtained')" class="question"><span>{{ assessment.marks_obtained }}</span> {{ $t('out of') }} <span>{{ assessment.total_marks }}</span></p>
                                    </div>
                                </div>

                                <div v-if="canOrganizeCandidate" class="d-flex">
                                    <a v-if="assessment.status!== 'ended'" @click.prevent="updateAssessment(assessment)"
                                        class="control-button control-button--primary has_bg_color">
                                        <div class="control-button__icon">
                                            <i class="eicon e-pencil"></i>
                                        </div>
                                    </a>
                                    <a @click.prevent="deleteAssessment(assessment)"
                                       class="control-button control-button--danger control-button--danger has_bg_color">
                                        <div class="control-button__icon">
                                            <i class="eicon e-delete"></i>
                                        </div>
                                    </a>
                                </div>
                            </div>

                            <div :id="`collapse-assessment-${assessment.id}`" class="collapse" :class="{'show':assessment.show}" aria-labelledby="" data-parent="#easyjobsAccordon">
                                <div class="details__card__body">
                                    <div class="d-flex justify-content-between align-items-center">
                                        <ul class="info__list question-answer">
                                            <li class="list-item--primary">
                                                <p class="question">{{ $t("Total Questions") }}: <span>{{ assessment.count }}</span></p>
                                            </li>
                                            <li class="list-item--primary">
                                                <p class="question">{{ $t("Total Marks") }}: <span>{{ assessment.total_marks }}</span></p>
                                            </li>
                                            <li class="list-item--primary">
                                                <p class="question">{{ $t("Expire date") }}: <label v-html="dateConvertToSpan(assessment.expire_date)"></label></p>
                                            </li>
                                            <li class="list-item--primary" v-if="assessment.hasOwnProperty('marks_obtained')">
                                                <p class="question">{{ $t("Marks Obtained") }}: <span>{{ assessment.marks_obtained }}</span></p>
                                            </li>
                                        </ul>
                                        <div class="text-right total-mark-obtained" v-if="assessment.hasOwnProperty('percent')">
                                            <apexchart type="radialBar" ref="quizChart" height="120" width="120" :options="assessmentChartOptions" :series="[assessment.percent]"></apexchart>
                                        </div>
                                    </div>
                                </div>
                                <div class="details__card__body" v-if="assessment.status === 'ended'">
                                    <ul class="info__list question-answer">
                                        <li class="list-item--primary" v-for="(question, index) in assessment.questions">
                                            <p class="question"><b>{{ $t("Question") }}: {{ (index + 1) }}.</b> {{ question.asked }}</p>
                                            <p class="label__full--modified label__full--primary answer">
                                                <span class="ans-label"> {{ $t("Applicant answered") }}: </span> {{ question.answer }} <span v-if="question.is_skipped" class="text-danger">{{ $t("Skipped") }}</span>
                                                <template v-if="question.type == job.QUESTION_TYPES.MULTIPLE_CHOICE">
                                                    <label v-if="!question.is_correct" class="correct-ans"><span class="prefix">{{ $t("Correct answer") }}: </span> {{ question.correct_answer }}</label>
                                                    <span v-if="question.is_correct" class="result-check correct"><i class="eicon e-checkmark"></i></span>
                                                    <span v-else-if="(!question.is_skipped && !question.is_correct)" class="result-check wrong"><i class="eicon e-close"></i></span>
                                                </template>
                                            </p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
            </div>

            <tab-attachment
                    v-if="isAttachment"
                    :applicant-id="candidate.id"
            />
        </template>
        <update-assessment :job="candidate.job_slug" :assessment="selectedAssessment"
                           v-if="showUpdateAssessment && canOrganizeCandidate"
                           @assessmentUpdated="assessmentUpdated"
                           @closeModal="showUpdateAssessment=false"></update-assessment>
    </div>
</template>

<script>
import client from "../../../app/api/Client";
import {mapState} from "vuex";
import {AI_SCORE_COLORS, JOB} from '../../../extra/constants';

import VueApexCharts from "vue-apexcharts";
import {radialChartOptions} from "../../../config/options";
import UpdateAssessment from "../../modal/job/UpdateAssessment";
import TabAttachment from "./TabAttachment";
import {dateConvertSpan} from "../../../extra/helper";
import {canOrganizeCandidate} from "../../../config/permission";


export default {
    components: {
        TabAttachment,
        apexchart: VueApexCharts,
        UpdateAssessment
    },
    props: ['candidate', 'tabData'],
    data() {
        return {
            showUpdateAssessment: false,
            selectedAssessment: false,
            candidateId: this.candidate.id,
            quiz: {
                count: 0,
                totalMarks: 0,
                marksObtained: 0
            },
            assessments: [],
            questions: [],
            quizQuestions: [],
            job: JOB,
            aiScoring: {},
            skillsSeries: [0],
            educationSeries: [0],
            experienceSeries: [0],
            totalSeries: [0],
            chartOptions: {
                ...radialChartOptions
            },
            quizChartOptions: {
                ...radialChartOptions,
                fill: {
                    type: 'solid',
                    colors: [AI_SCORE_COLORS.EDUCATION]
                }
            },
            assessmentChartOptions: {
                ...radialChartOptions,
                fill: {
                    type: 'solid',
                    colors: [AI_SCORE_COLORS.SKILLS]
                }
            },
            skillsChartOptions: {
                ...radialChartOptions,
                fill: {
                    type: 'solid',
                    colors: [AI_SCORE_COLORS.SKILLS]
                }
            },
            educationChartOptions: {
                ...radialChartOptions,
                fill: {
                    type: 'solid',
                    colors: [AI_SCORE_COLORS.EDUCATION]
                }
            },
            experienceChartOptions: {
                ...radialChartOptions,
                fill: {
                    type: 'solid',
                    colors: [AI_SCORE_COLORS.EXPERIENCE]
                }
            },
            totalChartOptions: {
                ...radialChartOptions,
                fill: {
                    type: 'solid',
                    colors: [AI_SCORE_COLORS.TOTAL]
                }
            },
            loading: false,
            isQuiz: false,
            isScreening: false,
            isAssessment: false,
            isAttachment: false,
            aiScoreCalculated: false,
        }
    },
    computed: {
        ...mapState(['company']),
        hasAssessment() {
            return this.assessments.length > 0;
        },
        hasQuiz() {
            return this.company.package_rule.quiz && this.quizQuestions.length > 0;
        },
        hasScreeningQuestion() {
            return this.company.package_rule.screening && this.questions.length > 0;
        },
        showQuiz() {
            return this.hasQuiz && this.isQuiz;
        },
        showScreeningQuestion() {
            return this.hasScreeningQuestion && this.isScreening;
        },
        showAssessment() {
            return this.isAssessment;
        },
        quizPercent() {
            return (this.quiz.marksObtained * 100 / this.quiz.totalMarks).toFixed(0);
        },
        hasEvaluation() {
            return (this.company.showAiScore || this.hasQuiz || this.hasScreeningQuestion);
        },

        showAiScore() {
            return (this.company.showAiScore && this.aiScoreCalculated);
        },
        isContentLoading() {
            return this.loading;
        },
        canOrganizeCandidate() {
            return canOrganizeCandidate();
        },
    },
    filters: {
        shortText: function (value) {
            if (!value) return '';
            if (value === undefined) return '';
            if (value.length > 15) {
                return value.slice(0, 15) + '...';
            }
            return value;
        }
    },
    methods: {
        showAssessmentDetails(id) {
            this.assessments = this.assessments.map(a => {
                if (a.id === id) {
                    a.show = !a.show;
                } else {
                    a.show = false;
                }
                return a;
            });
        },
        deleteAssessment(assessment) {
            let message = {
                title: this.$t(`Confirmation`),
                body: this.$t(`Are you sure, you want to remove this assessment?`)
            };
            let dialogConfig = {
                okText: this.$t('yes'),
                cancelText: this.$t('no'),
            };
            this.$dialog.confirm(message, dialogConfig).then(() => {
                client().delete(`/job/${this.candidate.job_slug}/assessment/${assessment.id}/delete`)
                    .then(({data: {data, message}}) => {
                        this.$toast.success(this.$t(message));
                    }).catch(({response: {data}}) => {
                    this.$toast.error(data.message);
                }).finally(() => {
                    this.getEvaluation();
                    this.switchTab(3);
                });
            });
        },
        async assessmentUpdated() {
            await this.getEvaluation();
            if (this.assessments.length > 0) {
                this.switchTab(3);
            }
            this.showUpdateAssessment = false;
        },
        updateAssessment(assessment) {
            this.selectedAssessment = assessment;
            this.showUpdateAssessment = true;
        },
        async getEvaluation() {
            this.loading = true;
            let {data: {data}} = await client().get(`job/applicant/${this.candidateId}/evaluation`);
            this.questions = data.questions;
            this.quiz = data.quiz;
            this.aiScoring = data.ai_scoring;
            this.quizQuestions = data.quiz_questions;
            this.aiScoreCalculated = data.ai_scoring_calculated;
            this.assessments = data.assessment_data;
            this.loading = false;
            this.updateCharts();
        },
        updateCharts() {
            if (this.showAiScore) {
                this.skillsSeries = [this.aiScoring.skills];
                this.educationSeries = [this.aiScoring.education];
                this.experienceSeries = [this.aiScoring.experience];
                this.totalSeries = [this.aiScoring.total];
            }
        },
        switchTab(tabId) {
            if (tabId === 1) {
                this.isScreening = false;
                this.isQuiz = true;
                this.isAssessment = false;
                this.isAttachment = false;
            } else if (tabId === 2) {
                this.isQuiz = false;
                this.isScreening = true;
                this.isAssessment = false;
                this.isAttachment = false;
            } else if (tabId === 3) {
                this.isAssessment = true;
                this.isScreening = false;
                this.isQuiz = false;
                this.isAttachment = false;
            } else if (tabId === 4) {
                this.isQuiz = false;
                this.isScreening = false;
                this.isAssessment = false;
                this.isAttachment = true;
            }
        },
        async tabInit() {
            let tabId = 4;
            if (this.hasQuiz) {
                tabId = 1;
            } else if (this.hasScreeningQuestion) {
                tabId = 2;
            }
            this.switchTab(tabId);
        },
        dateConvertToSpan(date) {
            return dateConvertSpan(date);
        }
    },
    async mounted() {
        await this.getEvaluation();
        await this.tabInit();
        if (this.assessments.length > 0) {
            this.assessments = this.assessments.map(a => {
                a.show = false;
                return a;
            });
        }
        if (this.tabData) {
            this.switchTab(this.tabData);
        }
    }


}
</script>



<style scoped>

.correct-ans .answer {
    color: #60ce83;
}

.question-answer .answer .result-check.wrong {
    font-size: 10px;
}

.details__card__body .empty-message {
    padding: 10px;
}

.details__card__body .empty-message:not(last-child) {
    margin-bottom: 10px;
}

.details__card__body .empty-message:not(first-child) {
    margin-top: 10px;
}

.empty-message {
    padding: 20px;
}

.label-primary {
    background: rgba(89, 125, 252, 0.15);
    color: #597dfc;
    border-radius: 20px;
    padding: 10px 10px;
    line-height: 1;
    font-weight: 500;
    display: inline-block;
}

.ended {
    background: rgba(96, 206, 131, 0.15) !important;
}

.not-completed {
    background: rgba(255, 95, 116, 0.15) !important;
}

.pipeline-card {
    display: flex;
    position: relative;
    background: rgba(89, 125, 252, 0.15);
    padding: 20px;
    border-radius: 10px;
}


.collapse-header {
    cursor: pointer;
}

.collapse-header-title {
    color: #597dfc;
}
.accordion>.card {
    border: unset;
}

.accordion > .card > .card-header {
    background: #ffffff;
    padding: .5rem;
    margin-bottom: 5px!important;
    border: 1px solid rgba(203, 209, 230, 0.3) !important;
    border-radius: 10px;

}

.assessment-header {
    color: #7d8091 !important;
}

.primary-label {
    font-size: 0.500rem;
}

.btn {
    padding: 0.175rem .40rem!important;
    font-size: .875rem!important;
}


/*New Css*/
.details__card .details__card__head .details__card__head__tab:first-of-type {
    border-top-left-radius: 10px;
}

.details__card .details__card__head .details__card__head__tab:last-of-type {
    border-top-right-radius: 10px;
}

.main__assessment__area {
    background: #f5f7fd !important;
    border-radius: 0;
}

.main__assessment__area .card {
    background: unset;
    margin-bottom: 5px;
    border-radius: 10px;
    background: #fff;
}

.main__assessment__area .card:first-of-type {
    border: 0 !important;
    border-radius: 0 0 10px 10px;
}

.main__assessment__area .card:first-of-type .card-header {
    border-radius: 0 0 10px 10px;
}

.main__assessment__area .card .card-header {
    margin-bottom: 0 !important;
    border: 0 !important;
    border-radius: 10px 10px 0 0;
}

.main__assessment__area .card .card-header.open-card-header {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.main__assessment__area .card .card-header.open-card-header {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.main__assessment__area .card .details__card__body {
    background: #fff !important;
    border: 0 !important;
    border-radius: 0 0 10px 10px;
}


</style>
